import { render, staticRenderFns } from "./List.vue?vue&type=template&id=25db6bca&scoped=true&"
import script from "./List.vue?vue&type=script&lang=js&"
export * from "./List.vue?vue&type=script&lang=js&"
import style0 from "./List.vue?vue&type=style&index=0&id=25db6bca&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "25db6bca",
  null
  
)

export default component.exports
import QSelect from 'quasar/src/components/select/QSelect.js';
import QItem from 'quasar/src/components/item/QItem.js';
import QItemSection from 'quasar/src/components/item/QItemSection.js';
import QBtnDropdown from 'quasar/src/components/btn-dropdown/QBtnDropdown.js';
import QList from 'quasar/src/components/item/QList.js';
import QCheckbox from 'quasar/src/components/checkbox/QCheckbox.js';
import QBtnToggle from 'quasar/src/components/btn-toggle/QBtnToggle.js';
import QInput from 'quasar/src/components/input/QInput.js';
import QBtn from 'quasar/src/components/btn/QBtn.js';
import QChip from 'quasar/src/components/chip/QChip.js';
import QTabs from 'quasar/src/components/tabs/QTabs.js';
import QTab from 'quasar/src/components/tabs/QTab.js';
import QInfiniteScroll from 'quasar/src/components/infinite-scroll/QInfiniteScroll.js';
import QSpinnerDots from 'quasar/src/components/spinner/QSpinnerDots.js';
import QDialog from 'quasar/src/components/dialog/QDialog.js';
import QLayout from 'quasar/src/components/layout/QLayout.js';
import QHeader from 'quasar/src/components/header/QHeader.js';
import QToolbar from 'quasar/src/components/toolbar/QToolbar.js';
import QIcon from 'quasar/src/components/icon/QIcon.js';
import QSpace from 'quasar/src/components/space/QSpace.js';
import QPageContainer from 'quasar/src/components/page/QPageContainer.js';
import QPage from 'quasar/src/components/page/QPage.js';
import QExpansionItem from 'quasar/src/components/expansion-item/QExpansionItem.js';
import QSeparator from 'quasar/src/components/separator/QSeparator.js';
import QTabPanels from 'quasar/src/components/tab-panels/QTabPanels.js';
import QTabPanel from 'quasar/src/components/tab-panels/QTabPanel.js';
import QItemLabel from 'quasar/src/components/item/QItemLabel.js';
import QMarkupTable from 'quasar/src/components/markup-table/QMarkupTable.js';
import QField from 'quasar/src/components/field/QField.js';import Ripple from 'quasar/src/directives/Ripple.js';
import ClosePopup from 'quasar/src/directives/ClosePopup.js';
import qInstall from "../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(component, 'components', {QSelect,QItem,QItemSection,QBtnDropdown,QList,QCheckbox,QBtnToggle,QInput,QBtn,QChip,QTabs,QTab,QInfiniteScroll,QSpinnerDots,QDialog,QLayout,QHeader,QToolbar,QIcon,QSpace,QPageContainer,QPage,QExpansionItem,QSeparator,QTabPanels,QTabPanel,QItemLabel,QMarkupTable,QField});qInstall(component, 'directives', {Ripple,ClosePopup});
