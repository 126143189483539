<template>
  <div class="list__body" style="background-color: #fff;">
    <div class="phouse__search--filterbox">
      <div ref="filter" style="border: 1px solid rgba(0, 0, 0, 0.24);width: 488px; border-radius: 3px;" v-if="query.action !== 'favourite'" class="phouse__search--filterlist dif">
        <q-select
          filled
          ref="name"
          v-model="filter.name.value"
          use-input
          input-debounce="500"
          :placeholder="$t('banner.searchplace')"
          :options="filter.name.list"
          @filter="searchArea"
          @input="nameSelecInput"
          lazy-rules
          bg-color="grey-1"
          behavior="menu"
          style="width: 486px"
        >
          <template v-slot:no-option>
            <q-item>
              <q-item-section class="text-grey">
                No results
              </q-item-section>
            </q-item>
          </template>
        </q-select>

        
        <!-- <q-btn-dropdown style="display: none" unelevated outline ref="type" auto-close no-caps :label="filter.type.label" :class="{invisible:!filter.type.show,absolute:!filter.type.show}" class="h40 ml10 filter__dropdown--box">
          <q-list>
            <q-item :focused="filter.type.label === typeItem.label" v-ripple @click="typeSelectInput(typeItem)" clickable :key="$index" v-for="(typeItem,$index) in filter.type.options">
              <q-item-section>{{typeItem.label}}</q-item-section>
            </q-item>
          </q-list>
        </q-btn-dropdown>

        <q-btn-dropdown unelevated outline :class="{invisible:!filter.price.show,absolute:!filter.price.show}" ref="price"  no-caps class="h40 filter__dropdown--box" :label="filter.price.label">
          <div class="w250 row q-col-gutter-md q-pa-xs">
            <q-select :label="$t('min')" class="col" dense map-options emit-value outlined v-model="filter.price.minvalue" option-label="text" option-value="id" :options="filter.price.minoptions" />
            <q-select :label="$t('max')" class="col" dense outlined map-options emit-value v-model="filter.price.maxvalue" option-label="text" option-value="id" :options="filter.price.maxoptions" />
          </div>
        </q-btn-dropdown>

        <q-btn-dropdown unelevated outline :class="{invisible:!filter.propertyType.show,absolute:!filter.propertyType.show}" ref="propertyType" no-caps class="h40 filter__dropdown--box" :label="filter.propertyType.label">
          <q-list class="q-pa-xs q-pt-sm">
            <q-item class="q-pa-sm" dense tag="label" v-ripple clickable :key="$index" v-for="(ptItem,$index) in filter.propertyType.options">
              <q-item-section side top>
                <q-checkbox dense @input="ptSelectInput(ptItem)" :val="ptItem.value" v-model="filter.propertyType.value" :label="ptItem.text" />
              </q-item-section>
            </q-item>
          </q-list>
        </q-btn-dropdown>

        <q-btn-dropdown unelevated outline :class="{invisible:!filter.bedrooms.show,absolute:!filter.bedrooms.show}" ref="bedrooms" class="h40 filter__dropdown--box" no-caps :label="filter.bedrooms.label">
          <q-btn-toggle @input="bedroomsInput" v-model="filter.bedrooms.value" :options="filter.bedrooms.options"></q-btn-toggle>
        </q-btn-dropdown>

        <q-btn-dropdown unelevated outline :class="{invisible:!filter.bathrooms.show,absolute:!filter.bathrooms.show}" ref="bathrooms" class="h40 filter__dropdown--box" no-caps :label="filter.bathrooms.label">
          <q-btn-toggle @input="bathroomsInput" v-model="filter.bathrooms.value" :options="filter.bathrooms.options"></q-btn-toggle>
        </q-btn-dropdown>

        <q-btn-dropdown unelevated outline :class="{invisible:!filter.square.show,absolute:!filter.square.show}" ref="square" class="h40 filter__dropdown--box"  no-caps :label="filter.square.label">
          <div class="w300 row q-col-gutter-md q-pa-xs">
            <q-select class="col" dense map-options emit-value outlined v-model="filter.square.minvalue" :options="filter.square.minoptions" />
            <q-select class="col" dense outlined map-options emit-value v-model="filter.square.maxvalue" :options="filter.square.maxoptions" />
          </div>
        </q-btn-dropdown>

        <q-btn-dropdown unelevated outline :class="{invisible:!filter.keyword.show,absolute:!filter.keyword.show}" ref="keyword" class="h40 filter__dropdown--box"  no-caps :label="filter.keyword.label">
          <div class="q-pa-xs">
            <q-input dense outlined v-model="filter.keyword.value" :label="$t('keword')">
              <template v-slot:append>
                <q-btn round dense @click="addKeywords" flat icon="add" />
              </template>
            </q-input>
            <q-chip removable color="blue" text-color="white" @remove="removeKeyword($index)" :key="$index" v-for="(keyword,$index) in filter.keyword.values">{{keyword}}</q-chip>
          </div>
        </q-btn-dropdown>

        <q-btn-dropdown unelevated outline :class="{invisible:!filter.year.show,absolute:!filter.year.show}" ref="year" class="h40 filter__dropdown--box" no-caps :label="filter.year.label">
          <q-select map-options emit-value filled v-model="filter.year.minvalue" :options="filter.year.minoptions" />
          <q-select filled map-options emit-value v-model="filter.year.maxvalue" :options="filter.year.maxoptions" />
        </q-btn-dropdown>

        <q-btn-dropdown unelevated outline :class="{invisible:!filter.lotSize.show,absolute:!filter.lotSize.show}" ref="lotSize" auto-close class="h40 filter__dropdown--box" no-caps :label="filter.lotSize.label">
          <q-list>
            <q-item :focused="filter.lotSize.label === lsItem.label" v-ripple @click="lotSizeSelectInput(lsItem)" clickable :key="$index" v-for="(lsItem,$index) in filter.lotSize.options">
              <q-item-section>{{lsItem.label}}</q-item-section>
            </q-item>
          </q-list>
        </q-btn-dropdown>

        <q-btn-dropdown unelevated outline :class="{invisible:!filter.status.show,absolute:!filter.status.show}" ref="status" no-caps class="h40 filter__dropdown--box" :label="filter.status.label">
          <q-list>
            <q-item
              tag="label"
              v-ripple
              clickable
              :key="statusItem.id"
              v-for="(statusItem,$index) in filter.status.options"
            >
              <q-item-section side top>
                <q-checkbox @input="statusSelectInput(statusItem,$index)" :disable="statusItem.disabled" v-model="statusItem.checked" :label="statusItem.label" />
              </q-item-section>
            </q-item>
          </q-list>
        </q-btn-dropdown> -->

        <q-btn-dropdown unelevated outline v-if="this.more.field.length" no-caps class="h40 filter__dropdown--box" :label="more.label">
          <div class="more__box">
            <div class="more__column" v-if="!filter.type.show">
              <div class="more__column--header">
                <span class="text-subtitle2 text-weight-bold">Listing Type</span>
              </div>
              <div class="more__column--body">
                <q-list>
                  <q-item :focused="filter.type.label === typeItem.label" v-ripple @click="typeSelectInput(typeItem)" clickable :key="$index" v-for="(typeItem,$index) in filter.type.options">
                    <q-item-section>{{typeItem.label}}</q-item-section>
                  </q-item>
                </q-list>
              </div>
            </div>
            <div class="more__column" v-if="!filter.price.show">
              <div class="more__column--header">
                <span class="text-subtitle2 text-weight-bold">Price</span>
              </div>
              <div class="more__column--body">
                <div class="row q-col-gutter-md">
                  <q-select class="col" dense @input="priceMinSelectInput" map-options emit-value outlined v-model="filter.price.minvalue" option-label="text" option-value="id" :options="filter.price.minoptions" />
                  <q-select class="col" dense @input="priceMaxSelectInput" outlined map-options emit-value v-model="filter.price.maxvalue" option-label="text" option-value="id" :options="filter.price.maxoptions" />
                </div>
              </div>
            </div>
            <div class="more__column" v-if="!filter.propertyType.show">
              <div class="more__column--header">
                <span class="text-subtitle2 text-weight-bold">Property Type</span>
              </div>
              <div class="more__column--body">
                <div class="row q-col-gutter-md">
                  <q-list class="q-pa-xs q-pt-sm">
                    <q-item class="q-pa-sm" dense tag="label" v-ripple clickable :key="$index" v-for="(ptItem,$index) in filter.propertyType.options">
                      <q-item-section side top>
                        <q-checkbox dense @input="ptSelectInput(ptItem)" v-model="filter.propertyType.value.indexOf(ptItem) > -1" :label="ptItem" />
                      </q-item-section>
                    </q-item>
                  </q-list>
                </div>
              </div>
            </div>
            <div class="more__column" v-if="filter.type.value[0] === 'Residential' && !filter.bedrooms.show">
              <div class="more__column--header">
                <span class="text-subtitle2 text-weight-bold">Bedrooms</span>
              </div>
              <div class="more__column--body">
                <div class="row q-col-gutter-md">
                  <q-btn-toggle flat size="sm" @input="bedroomsInput" v-model="filter.bedrooms.value" :options="filter.bedrooms.options"></q-btn-toggle>
                </div>
              </div>
            </div>
            <div class="more__column" v-if="filter.type.value[0] === 'Residential' && !filter.bathrooms.show">
              <div class="more__column--header">
                <span class="text-subtitle2 text-weight-bold">Bathroom</span>
              </div>
              <div class="more__column--body">
                <div class="row q-col-gutter-md">
                  <q-btn-toggle flat size="sm" @input="bathroomsInput" v-model="filter.bathrooms.value" :options="filter.bathrooms.options"></q-btn-toggle>
                </div>
              </div>
            </div>
            <div class="more__column" v-if="filter.type.value[0] !== 'Land' && !filter.square.show">
              <div class="more__column--header">
                <span class="text-subtitle2 text-weight-bold">Square Feet</span>
              </div>
              <div class="more__column--body">
                <div class="row q-col-gutter-md">
                  <q-select class="col" dense @input="priceMinSelectInput" map-options emit-value outlined v-model="filter.price.minvalue" option-label="text" option-value="id" :options="filter.price.minoptions" />
                  <q-select class="col" dense @input="priceMinSelectInput" map-options emit-value outlined v-model="filter.price.minvalue" option-label="text" option-value="id" :options="filter.price.minoptions" />
                </div>
              </div>
            </div>
            <div class="more__column" v-if="!filter.keyword.show">
              <div class="more__column--header">
                <span class="text-subtitle2 text-weight-bold">Keywords</span>
              </div>
              <div class="more__column--body">
                <q-input dense outlined v-model="filter.keyword.value" label="Street, MLS# or Keyword">
                  <template v-slot:append>
                    <q-btn round dense @click="addKeywords" flat icon="add" />
                  </template>
                </q-input>
                <q-chip removable color="blue" text-color="white" @remove="removeKeyword($index)" :key="$index" v-for="(keyword,$index) in filter.keyword.values">{{keyword}}</q-chip>
              </div>
            </div>
            <div class="more__column" v-if="filter.type.value[0] !== 'Land' && !filter.year.show">
              <div class="more__column--header">
                <span class="text-subtitle2 text-weight-bold">Year</span>
              </div>
              <div class="more__column--body">
                <div class="row q-col-gutter-md">
                  <q-select class="col" dense @input="yearMinSelectInput" map-options emit-value outlined v-model="filter.year.minvalue" :options="filter.year.minoptions" />
                  <q-select class="col" dense @input="yearMaxSelectInput" outlined map-options emit-value v-model="filter.year.maxvalue" :options="filter.year.maxoptions" />
                </div>
              </div>
            </div>
            <div class="more__column" v-if="!filter.lotSize.show">
              <div class="more__column--header">
                <span class="text-subtitle2 text-weight-bold">{{$t('filter.lotSize.label')}}</span>
              </div>
              <div class="more__column--body">
                <q-select dense @input="lotSizeSelectInput" map-options emit-value outlined v-model="filter.lotSize.value" :options="filter.lotSize.options" />
              </div>
            </div>
            <div class="more__column" v-if="!filter.status.show">
              <div class="more__column--header">
                <span class="text-subtitle2 text-weight-bold">Status</span>
              </div>
              <div class="more__column--body">
                <q-list>
                  <q-item
                    dense
                    tag="label"
                    v-ripple
                    clickable
                    :key="statusItem.id"
                    v-for="(statusItem,$index) in filter.status.options"
                  >
                    <q-item-section side top>
                      <q-checkbox dense @input="statusSelectInput(statusItem,$index)" :disable="statusItem.disabled" v-model="statusItem.checked" :label="statusItem.label" />
                    </q-item-section>
                  </q-item>
                </q-list>
              </div>
            </div>
          </div>
        </q-btn-dropdown>
      </div>
      <div v-if="query.action !== 'favourite'" class="hotcity__box mt15">
        <!-- <span class="hotcity__box--title">{{$t('hotareas')}}</span> -->
        <div class="hotcity__selectbox row q-col-gutter-md q-pa-xs">
          <!--:label="hotCity.label"-->
          <q-select
            class="col hotcity__selectbox--select"
            dense
            @input="hotCityItemClick"
            map-options
            emit-value
            outlined
            v-model="hotCity.value"
            option-label="label"
            option-value="value"
            :options="hotCity.options"
            v-for="hotCity in hotCityList"
            behavior="menu"            
          ></q-select>
          <q-btn @click="goNameFocus" text-color="grey-10" color="grey-6" dense class="h40 filter__btn" :label="$t('otherbtn')" outline />
          <!-- <span class="openMoreSearch" v-if="!showOrHideModule" @click="showOrHideSearch">{{$t('openMorebtn')}}</span> -->          
        </div>
      </div>
      
      <p class="hide-search" v-if="!showOrHideModule && query.action !== 'favourite'" style="height: 40px; line-height: 40px;"><span @click="showOrHideSearch">{{$t('openMorebtn')}}<i class="el-icon-arrow-down"></i></span></p>
      <div class="lh80" v-if="query.action === 'favourite'">
        <q-btn style="padding: 0 10px;" :to="{name:'buy',query:{action:'favourite'}}" class="mlr10" :label="$t('favourite.toBuyFavourite')" color="blue-7" />
        <q-btn style="padding: 0 10px;" :to="{name:'rentlist',query:{action:'favourite'}}" class="mlr10" :label="$t('favourite.toRentFavourite')" outline color="blue-7" />
      </div>
      <div class="moresearchModule" v-show="showOrHideModule">
        <ul>
          <li>
            <label class="label_title">{{$t('priceLabel')}}：</label>
            <div ref="price"  class="searchNum">
              <el-select size="mini" style="width:110px" v-model="filter.price.minvalue" :placeholder="$t('min')">
                <el-option
                  v-for="item in filter.price.minoptions"
                  :key="item.id"
                  :label="item.text"
                  :value="item.id">
                </el-option>
              </el-select>
              <span style="margin:0 10px;">{{$t('zhiM')}}</span>
              <el-select size="mini" style="width:120px"  v-model="filter.price.maxvalue" :placeholder="$t('max')">
                <el-option
                  v-for="item in filter.price.maxoptions"
                  :key="item.id"
                  :label="item.text"
                  :value="item.id">
                </el-option>
              </el-select>
            </div>
          </li>
          <li>
            <label class="label_title">{{filter.propertyType.label}}：</label>
            <!-- <span>
              <q-btn-dropdown unelevated outline :class="{invisible:!filter.propertyType.show,absolute:!filter.propertyType.show}" ref="propertyType" no-caps class="h40 filter__dropdown--box" :label="filter.propertyType.label">
                <q-list class="q-pa-xs q-pt-sm">
                  <q-item class="q-pa-sm" dense tag="label" v-ripple clickable :key="$index" v-for="(ptItem,$index) in filter.propertyType.options">
                    <q-item-section side top>
                      <q-checkbox dense @input="ptSelectInput(ptItem)" :val="ptItem.value" v-model="filter.propertyType.value" :label="ptItem.text" />
                    </q-item-section>
                  </q-item>
                </q-list>
              </q-btn-dropdown>
            </span> -->
            <div class="searchNum" ref="propertyType" >
              <el-checkbox-group  @change="ptSelectInput"  v-model="filter.propertyType.value">
                <el-checkbox v-for="(ptItem,$index) in filter.propertyType.options" :key="ptItem.value" :label="ptItem.value">{{ptItem.text}}</el-checkbox>
              </el-checkbox-group>
            </div>
          </li>
          <li>
            <label class="label_title">{{$t('filter.bedrooms.label')}}：</label>
            <div class="searchNum" ref="bedrooms">
              <el-radio-group @change="bedroomsInput" v-model="filter.bedrooms.value">
                <el-radio v-for="item in filter.bedrooms.options" :key="item.value" :label="item.value">{{item.label}}</el-radio>
              </el-radio-group>
            </div>
          </li>
          <li>
            <label class="label_title">{{filter.bathrooms.label}}：</label>
            <div class="searchNum" ref="bathrooms">
              <el-radio-group @change="bathroomsInput" v-model="filter.bathrooms.value">
                <el-radio v-for="item in filter.bathrooms.options" :key="item.value" :label="item.value">{{item.label}}</el-radio>
              </el-radio-group>
            </div>
          </li>
          <li>
            <label class="label_title">{{filter.square.label}}：</label>
            <!-- <span>
              <q-btn-dropdown unelevated outline :class="{invisible:!filter.square.show,absolute:!filter.square.show}" ref="square" class="h40 filter__dropdown--box"  no-caps :label="filter.square.label">
                <div class="w300 row q-col-gutter-md q-pa-xs">
                  <q-select class="col" dense map-options emit-value outlined v-model="filter.square.minvalue" :options="filter.square.minoptions" />
                  <q-select class="col" dense outlined map-options emit-value v-model="filter.square.maxvalue" :options="filter.square.maxoptions" />
                </div>
              </q-btn-dropdown>
            </span> -->
            <div class="searchNum" ref="square">
              <el-select size="mini" style="width:120px" v-model="filter.square.minvalue" :placeholder="$t('placeholderAll')">
                <el-option
                  v-for="item in filter.square.minoptions"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value">
                </el-option>
              </el-select>
              <span style="margin:0 10px;">{{$t('zhiM')}}</span>
              <el-select size="mini" style="width:120px"  v-model="filter.square.maxvalue" :placeholder="$t('placeholderAll')">
                <el-option
                  v-for="item in filter.square.maxoptions"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value">
                </el-option>
              </el-select>
            </div>
          </li>
          <li v-if="false">
            <label>{{$t('guanjianci')}}：</label>
            <span>
              <q-btn-dropdown unelevated outline :class="{invisible:!filter.keyword.show,absolute:!filter.keyword.show}" ref="keyword" class="h40 filter__dropdown--box"  no-caps :label="filter.keyword.label">
                <div class="q-pa-xs">
                  <q-input dense outlined v-model="filter.keyword.value" :label="$t('keword')">
                    <template v-slot:append>
                      <q-btn round dense @click="addKeywords" flat icon="add" />
                    </template>
                  </q-input>
                  <q-chip removable color="blue" text-color="white" @remove="removeKeyword($index)" :key="$index" v-for="(keyword,$index) in filter.keyword.values">{{keyword}}</q-chip>
                </div>
              </q-btn-dropdown>
            </span>
          </li>
          <li>
            <label class="label_title">{{$t('houseYear')}}：</label>
            <!-- <span>
              <q-btn-dropdown unelevated outline :class="{invisible:!filter.year.show,absolute:!filter.year.show}" ref="year" class="h40 filter__dropdown--box" no-caps :label="filter.year.label">
                <q-select map-options emit-value filled v-model="filter.year.minvalue" :options="filter.year.minoptions" />
                <q-select filled map-options emit-value v-model="filter.year.maxvalue" :options="filter.year.maxoptions" />
              </q-btn-dropdown>
            </span> -->
            <div class="searchNum" ref="year">
              <el-select size="mini" style="width:120px" v-model="filter.year.minvalue" :placeholder="$t('placeholderAll')">
                <el-option
                  v-for="item in filter.year.minoptions"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value">
                </el-option>
              </el-select>
              <span style="margin:0 10px;">{{$t('zhiM')}}</span>
              <el-select size="mini" style="width:120px"  v-model="filter.year.maxvalue" :placeholder="$t('placeholderAll')">
                <el-option
                  v-for="item in filter.year.maxoptions"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value">
                </el-option>
              </el-select>
            </div>
          </li>
          <li v-if="false">
            <label>{{$t('areaCovered')}}：</label>
            <q-btn-dropdown unelevated outline :class="{invisible:!filter.lotSize.show,absolute:!filter.lotSize.show}" ref="lotSize" auto-close class="h40 filter__dropdown--box" no-caps :label="filter.lotSize.label">
              <q-list>
                <q-item :focused="filter.lotSize.label === lsItem.label" v-ripple @click="lotSizeSelectInput(lsItem)" clickable :key="$index" v-for="(lsItem,$index) in filter.lotSize.options">
                  <q-item-section>{{lsItem.label}}</q-item-section>
                </q-item>
              </q-list>
            </q-btn-dropdown>
          </li>
          <li>
            <label class="label_title">{{filter.status.label}}：</label>
            <!-- <span>
              <q-btn-dropdown unelevated outline :class="{invisible:!filter.status.show,absolute:!filter.status.show}" ref="status" no-caps class="h40 filter__dropdown--box" :label="filter.status.label">
                <q-list>
                  <q-item
                    tag="label"
                    v-ripple
                    clickable
                    :key="statusItem.id"
                    v-for="(statusItem,$index) in filter.status.options"
                  >
                    <q-item-section side top>
                      <q-checkbox @input="statusSelectInput(statusItem,$index)" :disable="statusItem.disabled" v-model="statusItem.checked" :label="statusItem.label" />
                    </q-item-section>
                  </q-item>
                </q-list>
              </q-btn-dropdown>
            </span> -->
            <div class="searchNum" ref="status">
              <el-radio-group @change="(val)=>statusSelectInput(val)" v-model="filter.status.value">
                <el-radio v-for="(statusItem,$index) in filter.status.options" :disable="statusItem.disabled" :key="statusItem.id" :label="statusItem.value">{{statusItem.label}}</el-radio>
              </el-radio-group>
            </div>
          </li>
        </ul>
        <p v-if="showOrHideModule" class="hide-search"><span @click="hideOrHideSearch" >{{$t('stowcondition')}}<i class="el-icon-arrow-up"></i></span></p>
      </div>
    </div>
    <div :style="viewStyle" class="phouse__view clearfix">
      <div class="phouse__list">
        <q-tabs v-if="query.action !== 'favourite'" dense align="left"  color="blue-7" no-caps v-model="sort.index" text-primary class="phouse__list--tabs">
          <q-tab v-for="(sortField,index) in sortFieldTabs" @click="setSortField(index)" :name="index" text-primary :label="sortField.label" />
        </q-tabs>
        <ul id="houserList">
          <q-infinite-scroll
            ref="infinitescroll"
            scroll-target="#houserList"
            @load="houseListLoad"
            :offset="50">
            <li v-for="(house,$index) in houseList" @mouseenter="mouserEnterHouse($index)" @click="clickDialog(house.id,$index)" class="phouse__item clearfix">
              <div class="phouse__item--thumb">
                <img :src="house.images && house.images[0]" alt="">
                <q-btn @click.stop="collectHouse(house.id,$index,query.action)" class="phouse__item--collect" flat no-caps dense text-color="red-6" :icon="query.action === 'favourite' ? 'favorite':'favorite_border'"></q-btn>
                <span v-if="house.zzUpdateDate" class="phouse__item--info--updateDate">{{house.zzUpdateDate}}</span>
              </div>
              <div class="phouse__item--info clearfix">
                <div class="phouse__item--price__box">
                  <span class="phouse__item--price__num">${{house.listPrice | price2thousandth}}</span>
                </div>
                <div class="phouse__item--desc">
                  <h3 class="phouse__item--info--name">{{house.address.deliveryLine}}</h3>
                  <div class="phouse__item--info--column phouse__item--info--location">
                    <span class="phouse__item--info--icon phouse__item--info--location--icon material-icons">location_on</span>
                    <span class="phouse__item--info--content">{{house.address.street}}, {{house.address.city}}, {{house.address.state}} {{house.address.zip}}</span>
                  </div>
                  <div class="phouse__item--info--column phouse__item--info--laws">
                    <span class="phouse__item--info--icon phouse__item--info--laws-icon material-icons">insert_chart</span>
                    <span class="phouse__item--info--content">{{house.beds}} {{$t('detail.beds')}} | {{house.baths && house.baths.total}} {{$t('detail.baths')}} | {{house.size}}{{$t('detail.sqft')}} | {{house.propertyType}}</span>
                  </div>
                  <div class="phouse__item--info--column phouse__item--info--listing">
                    <span class="phouse__item--info--icon material-icons">star</span>
                    <span class="phouse__item--info--content">MLS#{{house.id}}</span>
                  </div>
                </div>
              </div>
            </li>
            <div v-if="!isloading && !houseList.length" class="row justify-center q-my-md list__nodata">
              You have not saved any favorite listings.
            </div>
            <template v-slot:loading>
              <div v-if="isloading" class="row justify-center q-my-md">
                <q-spinner-dots color="primary" size="40px" />
              </div>
            </template>
          </q-infinite-scroll>
        </ul>
      </div>
      <div v-if="query.action !== 'favourite'" id="phouseMap" class="phouse__map">
        <GmapMap
          :center="{lat: houseLatLng.length && houseLatLng[0].position.lat || googleCenter.lat,lng: houseLatLng.length && houseLatLng[0].position.lng || googleCenter.lng}"
          :zoom="13"
          map-type-id="roadmap"
          ref="mapRef"
        >
          <gmap-info-window
            :options="infoOptions"
            :position="infoWindowPos"
            :opened="infoWinOpen"
            @closeclick="infoWinOpen=false"
          ></gmap-info-window>

          <gmap-marker
            v-if="houseLatLng.length"
            :key="index"
            v-for="(m,index) in houseLatLng"
            :position="m.position"
            :visible="true"
            :clickable="true"
            @click="gMapMarkerClick(m,index)"
            @mouseover="gMapMarkerOver(m,index)"
          ></gmap-marker>
        </GmapMap>
      </div>
      <div v-if="query.action === 'favourite'" class="favourite__detailbox" style="float: right;height: 600px;">
        <div class="favourite__detailbox__img"><img src="../assets/images/6.jpg" alt=""></div>
        <div class="favourite__detailbox__text" v-html="$t('blog.buy.content')"></div>
      </div>
    </div>
    <q-dialog
      v-model="detailDialogStatus"
      maximized
    >
      <q-layout view="Lhh lpR fff" container class="bg-white phouse__pop">
        <q-header class="bg-grey-3">
          <q-toolbar>
            <!-- <q-icon color="grey-8" size="sm" name="location_on" /> -->
            <div class="text-h6 phouse__title"  style="color:#1871FF !important; font-weight:600;">{{currentDetailInfo.address.street}},{{currentDetailInfo.address.city}},{{currentDetailInfo.address.state}},{{currentDetailInfo.address.zip}}</div>
            <q-space />
            <!-- <q-btn icon="close" flat round dense v-close-popup /> -->
            <q-btn style="padding: 0 10px;" :label="$t('closeBtn')" color="primary" v-close-popup />
          </q-toolbar>
        </q-header>
        <q-page-container>
          <q-page>
            <swiper
              :options="swiperOptions"
            >
              <swiper-slide
                v-for="item in currentDetailInfo.images" :key="item"
              >
                <img :src="item" alt="">
              </swiper-slide>
              <div slot="button-next" class="swiper-button-next"></div>
              <div slot="button-prev" class="swiper-button-prev"></div>
            </swiper>

            <div class="phouse__detail--info q-pa-md">
              <div>
                <span class="price">${{currentDetailInfo.listPrice | price2thousandth}}</span>                
                <q-btn @click.stop="collectHouse(currentDetailInfo.id,currentIndex,query.action)" class="phouse__detail--collect" flat no-caps dense style="float: right;" text-color="red-6" :icon="query.action === 'favourite' ? 'favorite':'favorite_border'"></q-btn>
              </div>
              <div><span class="listing-status">{{(currentDetailInfo.status).toUpperCase()}}</span></div>
              <h3 class="phouse__detail--info--name clearfix text-h5 text-weight-light text-grey-8">
                <q-icon color="grey-8" size="sm" name="location_on" />
                {{currentDetailInfo.address.street}},{{currentDetailInfo.address.city}},{{currentDetailInfo.address.state}},{{currentDetailInfo.address.zip}}
              </h3>
              <div class="info">
                <q-icon color="grey-8" size="sm" style="margin-right: 10px; margin-left:-5px; vertical-align: top;" name="sensor_window" />
                <span class="phouse__detail--content--info-text--beds">{{currentDetailInfo.beds}} {{$t('detail.beds')}}</span>
                <span class="phouse__detail--content--info-text--baths">{{currentDetailInfo.baths.total}} {{$t('detail.baths')}}</span>
                <span class="phouse__detail--content--info-text--sqft">{{currentDetailInfo.size | price2thousandth}}{{$t('detail.sqft')}}</span>
                <span class="phouse__detail--content--info-text--presqft">{{Math.ceil(currentDetailInfo.listPrice / currentDetailInfo.size)}}/{{$t('detail.sqft')}}</span>
              </div>
              <ul class="phouse__detail--content--info-content" style="padding: 0 50px;">
                <li class="phouse__detail--content--info-content--mls">MLS# {{currentDetailInfo.id}}</li>
                <li class="phouse__detail--content--info-content--website">{{currentDetailInfo.daysOnHJI}} days on website</li>
                <li class="phouse__detail--content--info-content--part">{{currentDetailInfo.propertyType}}</li>
                <li class="phouse__detail--content--info-content--built">Built in {{currentDetailInfo.yearBuilt}}</li>
                <li class="phouse__detail--content--info-content--lakeview">{{currentDetailInfo.subdivision}}</li>
                <li class="phouse__detail--content--info-content--courtesy ">Courtesy of {{currentDetailInfo.listingAgent.name}}, {{currentDetailInfo.listingOffice.name}}.</li>
              </ul>
              <div class="phouse__detail--content--description-title"><span>{{$t('detail.HomeDetails')}}</span></div>
              <p class="phouse__detail--content--description-content">
                {{currentDetailInfo.description}}
              </p>
            </div>
            <div class="q-pa-md">

            <!-- <div class="tab-menu">
              <ul>
                <li @click="tabMenu(1)">{{$t('PropertyFactsFeatures')}}</li>
                <li @click="tabMenu(2)">{{$t('SchoolsNearby')}}</li>
                <span class="bottomBorder" :class="{'right':typeBoolean}"></span>
              </ul>
            </div> -->

            <div class="tab-con">              
              <div class="q-expansion-item__content" v-if="!typeBoolean">
                <div class="wrap">
                  <div class="content">
                    <ul class="features">
                      <li v-for="(feature,key) in currentDetailInfo.features">
                        <b>{{key}}</b>
                        <ul class="features-list">
                          <li v-for="item in feature">{{item}}</li>
                        </ul>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>

              <div class="bg-grey-1" style="margin-top:22px" v-if="typeBoolean">
                <q-tabs dense align="left" no-pane-border default no-caps v-model="school.model" style="border-bottom: none;">
                  <q-tab v-for="(tab) in school.tabs" :name="tab.name" @click="tab.click" :label="tab.label" />
                </q-tabs>
                <q-separator />
                <q-tab-panels v-model="school.model" class="bg-grey-1">
                  <q-tab-panel v-for="tab in school.tabs" :name="tab.name" :key="tab.name" class="q-pr-none q-pl-none">
                    <q-list>
                      <q-item-label class="rounded-borders q-pa-none" header>
                        <div class="row">
                          <div style="width: 100px" class="col text-center">{{$t('detail.RATING')}}</div>
                          <div style="width: 675px;" class="col-8">{{$t('detail.SCHOOLNAME')}}</div>
                          <div style="width: 140px;" class="col-2 text-center">{{$t('detail.ASSIGNEDGRADES')}}</div>
                          <div class="col text-center">{{$t('detail.DISTANCE')}}</div>
                        </div>
                      </q-item-label>

                      <q-item @click.native="getSchoolItem(schoolItem.id)" v-for="schoolItem in school.list" class="q-pr-none q-pl-none schools-q-item">
                        <div class="row schoolsitem">
                          <div style="width: 100px;align-self: center;" class="col text-center">
                            <div class="schoolsitem__avatar">{{schoolItem.rating}}</div>
                          </div>
                          <div style="width: 675px;" class="col-8">
                            <div class="schoolsitem__name">{{schoolItem.label}}</div>
                            <q-markup-table class="schoolsitem__info">
                              <thead>
                                <tr>
                                  <th class="text-left">{{$t('detail.STUDENTS')}}</th>
                                  <th class="text-center">{{$t('detail.STUDENTTEACH')}}</th>
                                  <th class="text-center">{{$t('detail.CHARTER')}}</th>
                                  <th class="text-center">{{$t('detail.TITLE1')}}</th>
                                  <th class="text-center">{{$t('detail.EXPENDITURE')}}</th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr>
                                  <td class="text-left"><b>{{schoolItem.totalStudents}}</b></td>
                                  <td class="text-right"><b>{{schoolItem.studentTeacherRatio}}</b></td>
                                  <td class="text-right"><b>{{schoolItem.charter ? "Yes" : "No"}}</b></td>
                                  <td class="text-right"><b>{{schoolItem.titleI}}</b></td>
                                  <td class="text-right"><b>${{schoolItem.expenditure | price2thousandth}}</b></td>
                                </tr>
                              </tbody>
                            </q-markup-table>
                          </div>
                          <div style="width: 140px;align-self: center;" class="col-2 text-center">{{schoolItem.gradeLow === "KG" ? "1" : schoolItem.gradeLow}} - {{schoolItem.gradeHigh}}</div>
                          <div class="col text-center" style="align-self: center;"><q-icon color="grey-8" size="sm" name="location_on" />  mi</div>
                        </div>
                      </q-item>
                    </q-list>
                  </q-tab-panel>
                </q-tab-panels>
              </div>

            </div>


              <q-expansion-item :label="$t('PropertyFactsFeatures')" switch-toggle-side default-opened header-class="bg-grey-2">
                <div class="wrap">
                  <div class="content">
                    <ul class="features">
                      <li v-for="(feature,key) in currentDetailInfo.features">
                        <b>{{key}}</b>
                        <ul class="features-list">
                          <li v-for="item in feature">{{item}}</li>
                        </ul>
                      </li>
                    </ul>
                  </div>
                </div>
              </q-expansion-item>
              <q-expansion-item :label="$t('SchoolsNearby')" switch-toggle-side default-opened header-class="bg-grey-2">
                <div class="bg-grey-1">
                  <q-tabs dense align="left" no-caps v-model="school.model">
                    <q-tab v-for="(tab) in school.tabs" :name="tab.name" :key="tab.name" @click="tab.click" :label="tab.label" />
                  </q-tabs>
                  <q-separator />
                  <q-tab-panels v-model="school.model" class="bg-grey-1">
                    <q-tab-panel v-for="tab in school.tabs" :name="tab.name" :key="tab.name" class="q-pr-none q-pl-none">
                      <q-list>
                        <q-item-label class="rounded-borders q-pa-none" header>
                          <div class="row">
                            <div style="width: 100px" class="col text-center">{{$t('detail.RATING')}}</div>
                            <div style="width: 675px;" class="col-8">{{$t('detail.SCHOOLNAME')}}</div>
                            <div style="width: 140px;" class="col-2 text-center">{{$t('detail.ASSIGNEDGRADES')}}</div>
                            <div class="col text-center">{{$t('detail.DISTANCE')}}</div>
                          </div>
                        </q-item-label>
                        <q-item @click.native="getSchoolItem(schoolItem.id)" v-for="schoolItem in school.list" class="q-pr-none q-pl-none schools-q-item">
                          <div class="row schoolsitem">
                            <div style="width: 100px;align-self: center;" class="col text-center">
                              <div class="schoolsitem__avatar">{{schoolItem.rating}}</div>
                            </div>
                            <div style="width: 675px;" class="col-8">
                              <div class="schoolsitem__name">{{schoolItem.label}}</div>
                              <q-markup-table class="schoolsitem__info">
                                <thead>
                                  <tr>
                                    <th class="text-left">{{$t('detail.STUDENTS')}}</th>
                                    <th class="text-center">{{$t('detail.STUDENTTEACH')}}</th>
                                    <th class="text-center">{{$t('detail.CHARTER')}}</th>
                                    <th class="text-center">{{$t('detail.TITLE1')}}</th>
                                    <th class="text-center">{{$t('detail.EXPENDITURE')}}</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  <tr>
                                    <td class="text-left"><b>{{schoolItem.totalStudents}}</b></td>
                                    <td class="text-right"><b>{{schoolItem.studentTeacherRatio}}</b></td>
                                    <td class="text-right"><b>{{schoolItem.charter ? "Yes" : "No"}}</b></td>
                                    <td class="text-right"><b>{{schoolItem.titleI}}</b></td>
                                    <td class="text-right"><b>${{schoolItem.expenditure | price2thousandth}}</b></td>
                                  </tr>
                                </tbody>
                              </q-markup-table>
                            </div>
                            <div style="width: 140px;align-self: center;" class="col-2 text-center">{{schoolItem.gradeLow === "KG" ? "1" : schoolItem.gradeLow}} - {{schoolItem.gradeHigh}}</div>
                            <div class="col text-center" style="align-self: center;"><q-icon color="grey-8" size="sm" name="location_on" />  mi</div>
                          </div>
                        </q-item>
                      </q-list>
                    </q-tab-panel>
                  </q-tab-panels>
                </div>
              </q-expansion-item>
            </div>
          </q-page>
        </q-page-container>
      </q-layout>
    </q-dialog>
  </div>
</template>

<script>
export default {
  name: "List",
  data(){
    let priceOptions = [
      {id:50000,text:"$50,000"},
      {id:100000,text:"$100,000"},
      {id:150000,text:"$150,000"},
      {id:200000,text:"$200,000"},
      {id:250000,text:"$250,000"},
      {id:300000,text:"$300,000"},
      {id:350000,text:"$350,000"},
      {id:400000,text:"$400,000"},
      {id:450000,text:"$450,000"},
      {id:500000,text:"$500,000"},
      {id:600000,text:"$600,000"},
      {id:700000,text:"$700,000"},
      {id:800000,text:"$800,000"},
      {id:900000,text:"$900,000"},
      {id:1000000,text:"$1,000,000"},
      {id:1100000,text:"$1,100,000"},
      {id:1200000,text:"$1,200,000"},
      {id:1300000,text:"$1,300,000"},
      {id:1400000,text:"$1,400,000"},
      {id:1500000,text:"$1,500,000"},
      {id:1600000,text:"$1,600,000"},
      {id:1800000,text:"$1,800,000"},
      {id:1900000,text:"$1,900,000"},
      {id:2000000,text:"$2,000,000"},
      {id:2250000,text:"$2,250,000"},
      {id:2500000,text:"$2,500,000"},
      {id:2750000,text:"$2,750,000"},
      {id:3000000,text:"$3,000,000"},
      {id:3250000,text:"$3,250,000"},
      {id:3500000,text:"$3,500,000"},
      {id:3750000,text:"$3,750,000"},
      {id:4000000,text:"$4,000,000"},
      {id:4500000,text:"$4,500,000"},
      {id:5000000,text:"$5,000,000"},
      {id:6000000,text:"$6,000,000"},
      {id:8000000,text:"$8,000,000"},
      {id:10000000,text:"$10,000,000"},
      {id:15000000,text:"$15,000,000"},
      {id:20000000,text:"$20,000,000"},
      {id:25000000,text:"$25,000,000"}
    ];
    let minPriceOptions = JSON.parse(JSON.stringify(priceOptions));
    minPriceOptions.unshift({id:"",text:this.$t('min')})
    let maxPriceOptions = JSON.parse(JSON.stringify(priceOptions));
    maxPriceOptions.unshift({id:"",text:this.$t('max')})

    let yearOptions = [
      {label:this.$t('any'),value:""},
      {label:"2021",value:2021},
      {label:"2020",value:2020},
      {label:"2019",value:2019},
      {label:"2018",value:2018},
      {label:"2017",value:2017},
      {label:"2016",value:2016},
      {label:"2015",value:2015},
      {label:"2014",value:2014},
      {label:"2013",value:2013},
      {label:"2012",value:2012},
      {label:"2011",value:2011},
      {label:"2010",value:2010},
      {label:"2009",value:2009},
      {label:"2008",value:2008},
      {label:"2007",value:2007},
      {label:"2006",value:2006},
      {label:"2005",value:2005},
      {label:"2004",value:2004},
      {label:"2003",value:2003},
      {label:"2002",value:2002},
      {label:"2001",value:2001},
      {label:"2000",value:2000},
      {label:"1999",value:1999},
      {label:"1998",value:1998},
      {label:"1997",value:1997},
      {label:"1996",value:1996},
      {label:"1995",value:1995},
      {label:"1994",value:1994},
      {label:"1993",value:1993},
      {label:"1992",value:1992},
      {label:"1991",value:1991},
      {label:"1990",value:1990},
      {label:"1970",value:1970},
      {label:"1980",value:1980},
      {label:"1960",value:1960},
      {label:"1950",value:1950},
      {label:"1940",value:1940},
      {label:"1930",value:1930}
    ];
    let minYearOptions = [
      {label:this.$t('startYear'),value:""},
      {label:"2021",value:2021},
      {label:"2020",value:2020},
      {label:"2019",value:2019},
      {label:"2018",value:2018},
      {label:"2017",value:2017},
      {label:"2016",value:2016},
      {label:"2015",value:2015},
      {label:"2014",value:2014},
      {label:"2013",value:2013},
      {label:"2012",value:2012},
      {label:"2011",value:2011},
      {label:"2010",value:2010},
      {label:"2009",value:2009},
      {label:"2008",value:2008},
      {label:"2007",value:2007},
      {label:"2006",value:2006},
      {label:"2005",value:2005},
      {label:"2004",value:2004},
      {label:"2003",value:2003},
      {label:"2002",value:2002},
      {label:"2001",value:2001},
      {label:"2000",value:2000},
      {label:"1999",value:1999},
      {label:"1998",value:1998},
      {label:"1997",value:1997},
      {label:"1996",value:1996},
      {label:"1995",value:1995},
      {label:"1994",value:1994},
      {label:"1993",value:1993},
      {label:"1992",value:1992},
      {label:"1991",value:1991},
      {label:"1990",value:1990},
      {label:"1970",value:1970},
      {label:"1980",value:1980},
      {label:"1960",value:1960},
      {label:"1950",value:1950},
      {label:"1940",value:1940},
      {label:"1930",value:1930}
    ];
    let maxYearOptions = [
      {label:this.$t('endYear'),value:""},
      {label:"2021",value:2021},
      {label:"2020",value:2020},
      {label:"2019",value:2019},
      {label:"2018",value:2018},
      {label:"2017",value:2017},
      {label:"2016",value:2016},
      {label:"2015",value:2015},
      {label:"2014",value:2014},
      {label:"2013",value:2013},
      {label:"2012",value:2012},
      {label:"2011",value:2011},
      {label:"2010",value:2010},
      {label:"2009",value:2009},
      {label:"2008",value:2008},
      {label:"2007",value:2007},
      {label:"2006",value:2006},
      {label:"2005",value:2005},
      {label:"2004",value:2004},
      {label:"2003",value:2003},
      {label:"2002",value:2002},
      {label:"2001",value:2001},
      {label:"2000",value:2000},
      {label:"1999",value:1999},
      {label:"1998",value:1998},
      {label:"1997",value:1997},
      {label:"1996",value:1996},
      {label:"1995",value:1995},
      {label:"1994",value:1994},
      {label:"1993",value:1993},
      {label:"1992",value:1992},
      {label:"1991",value:1991},
      {label:"1990",value:1990},
      {label:"1970",value:1970},
      {label:"1980",value:1980},
      {label:"1960",value:1960},
      {label:"1950",value:1950},
      {label:"1940",value:1940},
      {label:"1930",value:1930}
    ];
    let minInitYearOptions = [
      {label:this.$t('startYear'),value:""},
      {label:"2021",value:2021},
      {label:"2020",value:2020},
      {label:"2019",value:2019},
      {label:"2018",value:2018},
      {label:"2017",value:2017},
      {label:"2016",value:2016},
      {label:"2015",value:2015},
      {label:"2014",value:2014},
      {label:"2013",value:2013},
      {label:"2012",value:2012},
      {label:"2011",value:2011},
      {label:"2010",value:2010},
      {label:"2009",value:2009},
      {label:"2008",value:2008},
      {label:"2007",value:2007},
      {label:"2006",value:2006},
      {label:"2005",value:2005},
      {label:"2004",value:2004},
      {label:"2003",value:2003},
      {label:"2002",value:2002},
      {label:"2001",value:2001},
      {label:"2000",value:2000},
      {label:"1999",value:1999},
      {label:"1998",value:1998},
      {label:"1997",value:1997},
      {label:"1996",value:1996},
      {label:"1995",value:1995},
      {label:"1994",value:1994},
      {label:"1993",value:1993},
      {label:"1992",value:1992},
      {label:"1991",value:1991},
      {label:"1990",value:1990},
      {label:"1970",value:1970},
      {label:"1980",value:1980},
      {label:"1960",value:1960},
      {label:"1950",value:1950},
      {label:"1940",value:1940},
      {label:"1930",value:1930}
    ];
    let maxInitYearOptions = [
      {label:this.$t('endYear'),value:""},
      {label:"2021",value:2021},
      {label:"2020",value:2020},
      {label:"2019",value:2019},
      {label:"2018",value:2018},
      {label:"2017",value:2017},
      {label:"2016",value:2016},
      {label:"2015",value:2015},
      {label:"2014",value:2014},
      {label:"2013",value:2013},
      {label:"2012",value:2012},
      {label:"2011",value:2011},
      {label:"2010",value:2010},
      {label:"2009",value:2009},
      {label:"2008",value:2008},
      {label:"2007",value:2007},
      {label:"2006",value:2006},
      {label:"2005",value:2005},
      {label:"2004",value:2004},
      {label:"2003",value:2003},
      {label:"2002",value:2002},
      {label:"2001",value:2001},
      {label:"2000",value:2000},
      {label:"1999",value:1999},
      {label:"1998",value:1998},
      {label:"1997",value:1997},
      {label:"1996",value:1996},
      {label:"1995",value:1995},
      {label:"1994",value:1994},
      {label:"1993",value:1993},
      {label:"1992",value:1992},
      {label:"1991",value:1991},
      {label:"1990",value:1990},
      {label:"1970",value:1970},
      {label:"1980",value:1980},
      {label:"1960",value:1960},
      {label:"1950",value:1950},
      {label:"1940",value:1940},
      {label:"1930",value:1930}
    ];
    let squareOptions = [
      {label:this.$t('any'),value:""},
      {label:"600 sqft",value:600},
      {label:"800 sqft",value:800},
      {label:"1,000 sqft",value:1000},
      {label:"1,200 sqft",value:1200},
      {label:"1,400 sqft",value:1400},
      {label:"1,600 sqft",value:1600},
      {label:"1,800 sqft",value:1800},
      {label:"2,000 sqft",value:2000},
      {label:"2,200 sqft",value:2200},
      {label:"2,400 sqft",value:2400},
      {label:"2,600 sqft",value:2600},
      {label:"2,800 sqft",value:2800},
      {label:"3,000 sqft",value:3000},
      {label:"3,500 sqft",value:3500},
      {label:"4,000 sqft",value:4000},
      {label:"4,500 sqft",value:4500},
      {label:"5,000 sqft",value:5000},
      {label:"5,500 sqft",value:5500},
      {label:"6,000 sqft",value:6000}
    ];
    let initId = "b18e085845d8fd5def289ff0125d9e5c";
    return {
      showOrHideModule:false,
      curIndex:0,
      typeBoolean:false,
      initId:initId,
      currentIndex:0,
      filterNameAutoFocus:false,
      filter:{
        name:{
          value:this.$route.query.name,
          id:this.$route.query.id,
          rect:{
            right:0
          },
          show:true,
          list:[]
        },
        type:{
          label:this.$t('filter.type.Residential'),
          value:["Residential"],
          options:[
            {value:"Residential",label:this.$t('filter.type.Residential')},
            {value:"Multifamily",label:this.$t('filter.type.Multifamily')},
            {value:"Land",label:this.$t('filter.type.Land')},
            {value:"Commercial",label:this.$t('filter.type.Commercial')},
          ],
          rect:{
            right:0
          },
          show:true
        },
        price:{
          label:this.$t('filter.price.label'),
          value:"",
          minvalue:"",
          maxvalue:"",
          minoptions:minPriceOptions,
          maxoptions:maxPriceOptions,
          initoptions:priceOptions,
          initminopts:minPriceOptions,
          initmaxopts:maxPriceOptions,
          rect:{
            right:0
          },
          show:true
        },
        propertyType:{
          label:this.$t('filter.propertyType.label'),
          value:[],
          options:[],
          rect:{
            right:0
          },
          show:true
        },
        bedrooms:{
          label:this.$t('filter.bedrooms.label'),
          value:"",
          options:[
            {label: this.$t('any'), value: ''},
            {label: '1+', value: '1+'},
            {label: '2+', value: '2+'},
            {label: '3+', value: '3+'},
            {label: '4+', value: '4+'},
            {label: '5+', value: '5+'}
          ],
          rect:{
            right:0
          },
          show:true
        },
        bathrooms:{
            label:this.$t('filter.bathrooms.label'),
          value:"",
          options:[
            {label: this.$t('any'), value: ''},
            {label: '1+', value: '1+'},
            {label: '2+', value: '2+'},
            {label: '3+', value: '3+'},
            {label: '4+', value: '4+'},
            {label: '5+', value: '5+'}
          ],
          rect:{
            right:0
          },
          show:true
        },
        square:{
          label:this.$t('filter.square.label'),
          value:"",
          minvalue:"",
          maxvalue:"",
          initoptions: squareOptions,
          minoptions:squareOptions,
          maxoptions:squareOptions,
          rect:{
            right:0
          },
          show:true
        },
        keyword:{
          label:this.$t('filter.keyword.label'),
          value:"",
          values:[],
          rect:{
            right:0
          },
          show:true
        },
        year:{
          label:this.$t('filter.year.label'),
          value:"",
          minvalue:"",
          maxvalue:"",
          mininitoptions: minInitYearOptions,
          maxinitoptions: maxInitYearOptions,
          minoptions:minYearOptions,
          maxoptions:maxYearOptions,
          rect:{
            right:0
          },
          show:true
        },
        lotSize:{
          label:this.$t('filter.lotSize.label'),
          value:"",
          options:[
            {label:this.$t('any'),value:""},
            {label:"1+ Acres",value:"1+"},
            {label:"2+ Acres",value:"2+"},
            {label:"3+ Acres",value:"3+"},
            {label:"4+ Acres",value:"4+"},
            {label:"5+ Acres",value:"5+"},
            {label:"6+ Acres",value:"6+"},
            {label:"7+ Acres",value:"7+"},
            {label:"8+ Acres",value:"8+"},
            {label:"9+ Acres",value:"9+"},
            {label:"10+ Acres",value:"10+"},
            {label:"20+ Acres",value:"20+"},
            {label:"30+ Acres",value:"30+"},
            {label:"40+ Acres",value:"40+"},
            {label:"50+ Acres",value:"50+"}
          ],
          rect:{
            right:0
          },
          show:true
        },
        status:{
          label:this.$t('filter.status.label'),
          value:[],
          options:[
            {id:1,label:this.$t('any'),value:"",checked:true,disabled:true},
            {id:2,label:this.$t('filter.status.active'),value:"Active",checked:false,disabled:false},
            {id:3,label:this.$t('filter.status.activepending'),value:"Active Pending",checked:false,disabled:false},
          ],
          rect:{
            right:0
          },
          show:true
        },
      },
      propertyTypeOptionsMap:{
        Residential:[
          {
            value: 'Condo',text :this.$t('filter.propertyType.Residential.Condominium'),
          },
          {
            // this.$t('filter.propertyType.Residential.ForestServiceCabin'),
            // this.$t('filter.propertyType.Residential.MobileHome'),
            // this.$t('filter.propertyType.Residential.PartOwnership'),
            value:'Detached',text:this.$t('filter.propertyType.Residential.SingleFamilyResidence'),
          },
          {
            value: 'Attached',text:this.$t('filter.propertyType.Residential.Townhouse')
          }
        ],
        Multifamily:[
          this.$t('filter.propertyType.Multifamily.Build'),
          this.$t('filter.propertyType.Multifamily.Duplex'),
          this.$t('filter.propertyType.Multifamily.Quadruplex'),
          this.$t('filter.propertyType.Multifamily.Triplex')
        ],
        Land:[
          this.$t('filter.propertyType.Land.Land'),
          this.$t('filter.propertyType.Land.Allocation')
        ],
        Commercial:[
          this.$t('filter.propertyType.Commercial.Industrial'),
          this.$t('filter.propertyType.Commercial.Motel'),
          this.$t('filter.propertyType.Commercial.Office'),
          this.$t('filter.propertyType.Commercial.Professional'),
          this.$t('filter.propertyType.Commercial.RetailSpace'),
          this.$t('filter.propertyType.Commercial.OtherSee'),
          this.$t('filter.propertyType.Commercial.ProfessionalService'),
          this.$t('filter.propertyType.Commercial.ServiceRemarks'),
          this.$t('filter.propertyType.Commercial.GroceryStore')
        ],
      },
      more:{
        label:this.$t('More'),
        show:false,
        field:[]
      },
      houseList:[],
      detailDialogStatus:false,
      currentDetailInfo:{
        address:{
          street:"",
          city:"",
          state:"",
          zip:""
        },
        images:[],
        listPrice:0,
        status:"",
        yearBuilt:'',
        baths:{
          total:0
        },
        daysOnHJI:0,
        propertyType:"",
        subdivision:"",
        listingAgent:{
          name:""
        },
        listingOffice:{
          name:""
        },
        features:{}
      },
      swiperOptions:{
        slidesPerView: 3,
        slidesPerColumn: 2,
        spaceBetween: 2,
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev',
        },
        zoom:1
      },
      school:{
        model:"assigned",
        tabs:[
          {
            name:"assigned",
            label:this.$t('school.tabs.Assigned'),
            click:this.requestAssignedSchools
          },
          {
            name:"elementary",
            label:this.$t('school.tabs.Elementary'),
            click:this.requestSaleSchoolSearch.bind(this,'elementary')
          },
          {
            name:"middle",
            label:this.$t('school.tabs.Middle'),
            click:this.requestSaleSchoolSearch.bind(this,'middle')
          },
          {
            name:"high",
            label:this.$t('school.tabs.High'),
            click:this.requestSaleSchoolSearch.bind(this,'high')
          }
        ],
        list:[],
        district:""
      },
      houseLatLng:[],
      sortFieldTabs:[
        { label:this.$t('listSort.Newest'), value:"daysOnHJI",order:"asc"},
        { label:this.$t('listSort.Cheapest'), value:"listPrice",order:"asc"},
        { label:this.$t('listSort.OpenHouse'), value:"openHouse",order:"asc"},
        { label:this.$t('listSort.Price'), value:"listPrice",order:"desc"},
        { label:this.$t('listSort.Largest'), value:"size",order:"asc"},
        { label:this.$t('listSort.YearBuilt'), value:"yearBuilt",order:"asc"}
      ],
      sort:{
        field:"daysOnHJI",
        order:"asc",
        index:0
      },
      infoOptions: {
        content: '',
        //optional: offset infowindow so it visually sits nicely on top of our marker
        pixelOffset: {
          width: 0,
          height: -35
        }
      },
      infoWindowPos:null,
      infoWinOpen:false,
      currentMidx:null,
      page:{
        number:1,
        size:10,
        count:0
      },
      query:{
        action:this.$route.query.action,
        id:this.$route.query.id,
        name:this.$route.query.name,
        ...this.$route.query
      },
      isloading:false,
      viewStyle:{
        height:"calc(100% - 60px)"
      },
      hotCityList:[
        {
          label:this.$t('MoreLexingtonAreas'),
          value:this.$route.query.action === 'favourite' ? "" : this.$route.query.id ? "" : initId,
          options:[
            { label:"Lexington", value:"" },
            { label:"02421, Lexington, MA", value:"b18e085845d8fd5def289ff0125d9e5c" },
            { label:"02420, Lexington, MA", value:"3faab9530802bcd4203ba6a73908d809" }
          ]
        },
        {
          label:this.$t('MoreNewtonAreas'),
          value:"",
          options:[
            { label:"Newton", value:"" },
            { label:"02468, Waban, MA", value:"45364e3ad2199f2dfb4e207db6665734" },
            { label:"02467, Chestnut Hill, MA", value:"0113471c72c54da35f6687b5ab8bd03e" },
            { label:"02466, Auburndale, MA", value:"3f342ea8f036873b0b25a3c3ad11791c" },
            { label:"02465, West Newton, MA", value:"794f99b410d657983e0f66beb09e19a6" },
            { label:"02464, Newton Upper Falls, MA", value:"83fa72ea4b9a0fe95bd9603c621626cc" },
            { label:"02462, Newton Lower Falls, MA", value:"e751d2b666845e5636f503edb71c61a1" },
            { label:"02461, Newton Highlands, MA", value:"32433af9c31b84c2dcdea45ee5de2958" },
            { label:"02460, Newtonville, MA, Lexington, MA", value:"3aa46e5befc7838fc55d3369a58ff968" },
            { label:"02459, Newton Center, MA", value:"94f8ff968d02e32ac3cf8167d6da3aa8" },
            { label:"02458, Newton, MA", value:"731495c8931fee96d1c3b7c026b5d626" }

          ]
        },
        {
          label:this.$t('MoreWellesleyAreas'),
          value:"",
          options:[
            { label:"Wellesley", value:"" },
            { label:"02482, Wellesley, MA", value:"0be50a104da7eb158cd3ed225d4df479" },
            { label:"02481, Wellesley Hills, MA", value:"97ae9f6eca8c6b6f7ba344e0d338d300" }
          ]
        },
        {
          label:this.$t('MoreWestonAreas'),
          value:"",
          options:[
            { label:"Weston", value:"" },
            { label:"02493, Weston, MA", value:"db4e5f9927c0f3d1e0c3b5a3c18d3448" }
          ]
        },
      ],
      mapbox:[],
      google:null,
      googleCenter:{
        lat:51.36,
        lng:-0.05
      }
    }
  },
  watch:{
    "filter.type.value":{
      handler(n,o){
        this.filter.propertyType.options = this.propertyTypeOptionsMap[n[0]];
        this.filter.propertyType.value = [];
      },
      immediate:true
    },
    "filter.price.minvalue"(n,o){
      this.handlePriceSelect(n,this.filter.price.maxvalue);
    },
    "filter.price.maxvalue"(n,o){
      this.handlePriceSelect(this.filter.price.minvalue,n);
    },
    "filter.square.minvalue"(n,o){
      this.handleSquareSelect(n,this.filter.square.maxvalue)
    },
    "filter.square.maxvalue"(n,o){
      this.handleSquareSelect(this.filter.square.minvalue,n)
    },
    "filter.year.minvalue"(n,o){
      this.handleYearSelect(n,this.filter.year.maxvalue,"min")
    },
    "filter.year.maxvalue"(n,o){
      this.handleYearSelect(this.filter.year.minvalue,n,"max")
    },
    "$route"(n,o){
      this.houseList = [];
      this.query.action = n.query && n.query.action;
      let cityclear = true;
      if(this.query.action !== "favourite"){
        this.hotCityList.forEach(city=>{
          let options = city.options;
          let currentOption = options.filter(option=>option.value === this.initId);
          if(currentOption.length){
            city.value = currentOption[0].value;
          }
        });
        cityclear = false;
      }
      this.resetLoad({cityclear});
    }
  },
  computed:{
    getSearchParams(){
      return {
        // name:this.filter.name.value || undefined,
        listingType:this.filter.type.value,
        listPrice:this.filter.price.value || undefined,
        propertyType:this.filter.propertyType.value || undefined,
        beds:this.filter.bedrooms.value || undefined,
        baths:this.filter.bathrooms.value || undefined,
        size:this.filter.square.value || undefined,
        keyword:!this.filter.keyword.values.length ? undefined : this.filter.keyword.values.join(),
        lotSize:this.filter.lotSize.value || undefined,
        status:this.filter.status.value || undefined,
        sortField:this.sort.field || undefined,
        sort:this.sort.order || undefined,
        yearBuilt:this.filter.year.value || undefined,
        pageNumber:this.page.number,
        pageSize:this.page.size,
        box: this.mapbox.join(",") || undefined
      }
    }
  },
  methods:{
    tabMenu(type){
      if(type === 1){
        this.typeBoolean = false
      }
      if(type === 2){
        this.typeBoolean = true
      }
    },
    showOrHideSearch(){
      if(!this.showOrHideModule){
        this.showOrHideModule = true
      }else{
        this.showOrHideModule = false
      }
    },
    hideOrHideSearch(){
      this.showOrHideModule = false
    },
    async searchArea(name, update, abort){
      if(!name){
        update();
        return false;
      }
      let form = new FormData();
      // form.append("polygon","@hjimls")
      // form.append("polygon","@icaor")
      form.append("polygon","@mlspin")
      let res = await this.$api.requestAreasSearch(form,{
        headers: {
          "Content-Type":"application/x-www-form-urlencoded"
        },
        params: {
          geoType: ["area/postal-city","area/census-place","area/township","area/county","area/neighborhood","area/zipcode"],
          keyword: name,
          sortField: "population",
          sortOrder: "desc",
          bias: "postal-city",
          clipping: "intersection"
        }
      });
      let result = res.result;
      let areas = result.areas;
      let _this = this;
      update(()=>{
        _this.filter.name.list = areas;
      })
    },
    nameSelecInput(school){
      this.filter.name.value = school ? school.label : "";
      this.filter.name.id = school ? school.id : "";
      this.resetLoad({cityclear:true});
    },
    typeSelectInput(type){
      this.filter.type.label = type.label;
      this.filter.type.value.splice(0,1,type.value);
      this.filterFieldShow("typeselect");
      this.resetLoad();
    },
    handlePriceSelect(minprice,maxprice){
      let initminopts = this.filter.price.initminopts;
      let initmaxopts = this.filter.price.initmaxopts;
      let currentMinOption = initminopts.filter(function(item){
        return item.id === minprice
      })[0];
      let currentMaxOption = initmaxopts.filter(function(item){
        return item.id === maxprice
      })[0];
      let maxOptions = initmaxopts.filter(function(item){
        let id = item.id;
        if(id === ""){
          return true;
        }
        return id > minprice;
      });
      this.filter.price.maxoptions = maxOptions;
      let label = "";
      if(minprice === "" && maxprice === ""){
        label = `${this.$t('filter.price.label')}`;
        this.filter.price.value = "";
      }else if(minprice === "" && maxprice > 0){
        label = `<${currentMaxOption.text}`;
        this.filter.price.value = `${maxprice}-`;
      }else if((minprice > 0 && maxprice === "" )|| (minprice === maxprice)) {
        label = `>${currentMinOption.text}`;
        this.filter.price.value = `${minprice}+`;
      }else if(minprice > 0 && maxprice > 0 && minprice < maxprice){
        label = `${currentMinOption.text} - ${currentMaxOption.text}`;
        this.filter.price.value = `${minprice}:${maxprice}`;
      }else if(minprice > maxprice && maxprice > 0){
        label = `${currentMaxOption.text} - ${currentMinOption.text}`;
        this.filter.price.minvalue = maxprice;
        this.filter.price.maxvalue = minprice;
        this.filter.price.value = `${maxprice}:${minprice}`;
      }
      this.filter.price.label = label;
      this.resetLoad();
    },
    handleSquareSelect(minsquare,maxsquare){
      let initoptions = this.filter.square.initoptions;
      let currentMinOption = initoptions.filter(function(item){
        return item.value === minsquare
      })[0];
      let currentMaxOption = initoptions.filter(function(item){
        return item.value === maxsquare
      })[0];
      let maxOptions = initoptions.filter(function(item){
        let id = item.value;
        if(id === ""){
          return true;
        }
        return id > minsquare;
      });
      this.filter.square.maxoptions = maxOptions;
      let label = "";
      if(minsquare === "" && maxsquare === ""){
        label = `${this.$t('filter.square.label')}`;
        this.filter.square.value = "";
      }else if(minsquare === "" && maxsquare > 0){
        label = `<${currentMaxOption.label}`;
        this.filter.square.value = `${maxsquare}-`;
      }else if((minsquare > 0 && maxsquare === "" )|| (minsquare === maxsquare)) {
        label = `>${currentMinOption.label}`;
        this.filter.square.value = `${minsquare}+`;
      }else if(minsquare > 0 && maxsquare > 0 && minsquare < maxsquare){
        label = `${currentMinOption.label} - ${currentMaxOption.label}`;
        this.filter.square.value = `${minsquare}:${maxsquare}`;
      }else if(minsquare > maxsquare && maxsquare > 0){
        label = `${currentMaxOption.label} - ${currentMinOption.label}`;
        this.filter.square.minvalue = maxsquare;
        this.filter.square.maxvalue = minsquare;
        this.filter.square.value = `${maxsquare}:${minsquare}`;
      }
      this.filter.square.label = label;
      this.resetLoad();
    },
    handleYearSelect(minsyear,maxyear,type){
      let mininitoptions = this.filter.year.mininitoptions;
      let maxinitoptions = this.filter.year.maxinitoptions;
      let currentMinOption = mininitoptions.filter(function(item){
        return item.value === minsyear
      })[0];
      let currentMaxOption = maxinitoptions.filter(function(item){
        return item.value === maxyear
      })[0];
      let maxOptions = maxinitoptions.filter(function(item){
        let id = item.value;
        if(id === ""){
          return true;
        }
        return id > minsyear;
      });
      this.filter.year.maxoptions = maxOptions;
      let label = "";
      if(minsyear === "" && maxyear === ""){
        label = `${this.$t('filter.year.label')}`;
        this.filter.year.value = "";
      }else if(minsyear === "" && maxyear > 0){
        label = `<${currentMaxOption.label}`;
        this.filter.year.value = `${maxyear}-`;
      }else if((minsyear > 0 && maxyear === "" )|| (minsyear === maxyear)) {
        label = `>${currentMinOption.label}`;
        this.filter.year.value = `${minsyear}+`;
      }else if(minsyear > 0 && maxyear > 0 && minsyear < maxyear){
        label = `${currentMinOption.label} - ${currentMaxOption.label}`;
        this.filter.year.value = `${minsyear}:${maxyear}`;
      }else if(minsyear > maxyear && minsyear > 0){
        label = `${currentMaxOption.label} - ${currentMinOption.label}`;
        this.filter.year.minvalue = maxyear;
        this.filter.year.maxvalue = minsyear;
        this.filter.year.value = `${maxyear}:${minsyear}`;
      }
      this.filter.year.label = label;
      this.resetLoad();
    },
    ptSelectInput(pt){
      console.log('pt',pt)
      let propertyTypeValue = this.filter.propertyType.value;
      this.filter.propertyType.label = propertyTypeValue.length ? `${this.$t('filter.propertyType.label')}(${propertyTypeValue.length})` : `${this.$t('filter.propertyType.label')}`;
      this.resetLoad();
    },
    bedroomsInput(value){
      this.filter.bedrooms.label = `${this.$t('filter.bedrooms.label')}${value ? "("+value+")" : ''}`;
      //this.$refs.bedrooms.hide();
      this.resetLoad();
    },
    bathroomsInput(value){
      console.log('value,',value)
      this.filter.bathrooms.label = `${this.$t('filter.bathrooms.label')}${value ? "("+value+")" : ''}`;
      //this.$refs.bathrooms.hide();
      this.resetLoad();
    },
    addKeywords(){
      let value = this.filter.keyword.value;
      if(value){
        this.filter.keyword.values.push(value);
        this.filter.keyword.value = "";
        this.resetLoad();
      }
    },
    removeKeyword(index){
      this.filter.keyword.values.splice(index,1);
      this.resetLoad();
    },
    lotSizeSelectInput(lotSize){
      this.filter.lotSize.label = lotSize.value ? lotSize.label : `${this.$t('filter.lotSize.label')}`;
      if('value' in lotSize){
        this.filter.lotSize.value = lotSize.value;
      }
      this.resetLoad();
    },
    statusSelectInput(status,index){
      // let statusValue = this.filter.status.value;
      // let currentStatusValue = status.value;
      // let currentStatusChecked = status.checked;
      // if(index > 0){
      //   this.$set(this.filter.status.options,0,Object.assign({},this.filter.status.options[0],{
      //     checked: false,
      //     disabled:false
      //   }));
      //   if(currentStatusChecked){
      //     statusValue.push(currentStatusValue);
      //   }else{
      //     let i = statusValue.indexOf(currentStatusValue);
      //     statusValue.splice(i,1);
      //   }
      // }else{
      //   this.$set(this.filter.status.options,1,Object.assign({},this.filter.status.options[1],{
      //     checked: false
      //   }));
      //   this.$set(this.filter.status.options,2,Object.assign({},this.filter.status.options[2],{
      //     checked:false
      //   }));
      //   this.$set(this.filter.status.options,0,Object.assign({},this.filter.status.options[0],{
      //     disabled:true
      //   }));
      //   statusValue.splice(0,statusValue.length);
      // }
      // this.filter.status.label = statusValue.length ? statusValue.length === 1 ? statusValue[0] : `${this.$t('filter.status.label')}(${statusValue.length})` : `${this.$t('filter.status.label')}`;
      this.resetLoad();
    },
    async requestSaleListingsSearch(params,type,form){
      let resultRes;
      let searchRes;
      let resultList = [];
      let resultPaging;
      params = Object.assign({},{},params)
      searchRes = resultRes = await this.$api.saleListingsSearch(form,{
        params
      });
      let result = resultRes.result;
      let success = resultRes.success;
      let listings = resultList = result.listings;
      let ids = listings.map(item=>{
        return item.id;
      });
      resultPaging = result.paging;
      this.page.count = resultPaging.count;
      if(ids.length){
        resultRes = await this.$api.getSaleList({
          id:ids,
          details: true,
          features: false,
          images: true
        })
        let getresult = resultRes.result;
        resultPaging = getresult.paging;
        resultList = getresult.listings;
        resultList.forEach(item=>{
          item.zzUpdateDate = item.releaseDate ? `${item.releaseDate}${this.$t('beforeUpdate')}` : this.$t('justUpdate')
        })
      }
      return {
        resultList,
        result:searchRes.result
      };
    },
    async requestGetSaleList(id){
      let res = await this.$api.getSaleList({
        id:id,
        details:true,
        features:true,
        images:true
      });
      let success = res.success;
      let result = res.result;
      let listings = result.listings;
      this.currentDetailInfo = listings[0];
    },
    async clickDialog(id,$index){
      this.detailDialogStatus = true;
      this.currentIndex = $index;
      await this.requestGetSaleList(id);
      await this.requestAssignedSchools();
    },
    mouserEnterHouse(index){
      this.gMapMarkerOver(this.houseLatLng[index]);
    },
    async houseListLoad(index, done){
      let form = null;
      let params = {};
      let houseList = [];
      let resultRes;
      if(index === 1){
        this.houseList = []
      }
      let currentID = this.$route.query.id || this.filter.name.id;
      let hasIDCity = this.hotCityList.filter(city=>!!city.value);
      if(hasIDCity.length){
        currentID = hasIDCity[0].value
      }
      if(currentID){
        form = new FormData();
        form.append("polygon","$"+currentID);
        this.getAreaLatitudeLongitude();
        params = Object.assign({},this.getSearchParams,{
          clipping: "intersection",
          pageNumber:index
        })
      }else if(this.query.action !== 'favourite'){
        this.mapbox = [];
        params = Object.assign({},this.getSearchParams,{
          pageNumber:index
        });
      }else{
        params = {
          pageNo:index
        }
      }
      this.isloading = true;
      if(currentID || this.query.action !== 'favourite'){
        let {resultList, result} = await this.requestSaleListingsSearch(params,null,form);
        houseList = resultList;
        resultRes = result;
      }else{
        let {resultList, result} = await this.requestGetFavouriteSaleList(params)
        houseList = resultList;
        resultRes = result;
      }
      this.isloading = false;
      if(index > 1){
        this.houseList = this.houseList.concat(houseList);
      }else{
        this.houseList = houseList;
      }

      let houseLatLng = this.houseList.map((listing)=>{
        return {
          position:{
            lat:listing?.coordinates.latitude,
            lng:listing?.coordinates.longitude,
          },
          infoText:`<div class="infowindow__box">
                        <div class="infowindow__box--image"><img src="${listing.images && listing.images[0]}"></div>
                        <div class="infowindow__box--name">${listing.address && listing.address.deliveryLine}</div>
                    </div>`,
          id:listing.id
        }
      });

      let paging = resultRes?.paging;
      let pagingCount = paging?.count || 1;
      let pagingSize = paging?.size;
      let pagingNumber = paging?.number;
      this.houseLatLng = houseLatLng;

      done(index >= pagingCount);
    },
    async requestGetFavouriteSaleList(params){
      let resultList = [];
      let res = await this.$api.requestGetFavouriteSaleList(params);
      let code = res.code;
      let result;
      if(code === 1000){
        let data = res?.data;
        let total = data?.total;
        let list = data?.list;
        let listResult = list?.result;
        let listings = listResult?.listings;
        resultList = listings || [];
        result = listResult;
      }
      return {
        resultList,
        result
      };
    },
    async requestAssignedSchools(){
      this.curIndex = 0
      let coordinates = this.currentDetailInfo.coordinates;
      let latitude = coordinates.latitude;
      let longitude = coordinates.longitude;
      let res = await this.$api.saleSchoolAttendanceAreasSearch({
        coords:latitude + "," + longitude,
        details:true,
        pageSize:10,
        sortField:"distance",
        sortOrder:"asc"
      });
      let result = res.result;
      let schools = result.schools;
      let districts = result.districts;
      for(let i in districts){
        this.school.districts = i;
      }
      let schoolIds = Object.keys(schools);
      let getSchoolRes = await this.$api.saleSchoolsGet({
        id:schoolIds,
        details:true
      });

      let schoolsResult = getSchoolRes.result;
      let schoolsList = schoolsResult.schools;
      this.school.list = schoolsList;
    },
    async requestSaleSchoolSearch(grade){
      if(grade.includes('elementary')){
        console.log('yyy')
        this.curIndex = 1
      }else if(grade.includes('middle')){
        this.curIndex = 2
      }else if(grade.includes('high')){
        this.curIndex = 3
      }
      
      let coordinates = this.currentDetailInfo.coordinates;
      let latitude = coordinates.latitude;
      let longitude = coordinates.longitude;
      let res = await this.$api.saleSchoolSearch({
        district:this.school.districts,
        sortField:"distance",
        grade:grade,
        circle:latitude + "," + longitude+",5",
        clipping:"intersection",
        pageSize:100,
        details:true
      });
      let schoolsResult = res.result;
      let schoolsList = schoolsResult.schools;
      this.school.list = schoolsList;
    },
    //获取学校详情
    async getSchoolItem(schoolid){
      let getSchoolRes = await this.$api.saleSchoolsGet({
        id:schoolid,
        details:true
      });
      let schoolsResult = getSchoolRes.result;
      let schoolsList = schoolsResult.schools;
    },
    //选择排序类型
    setSortField(index){
      let currentSortField = this.sortFieldTabs[index];
      this.sort.field = currentSortField.value;
      this.sort.order = currentSortField.order;
      this.sort.index = currentSortField.index;
      this.resetLoad();
    },
    gMapMarkerClick(marker){
      this.infoWindowPos = marker.position;
      this.infoOptions.content = marker.infoText;
      if (this.currentMidx == marker.id) {
        this.infoWinOpen = !this.infoWinOpen;
      }
      else {
        this.infoWinOpen = true;
        this.currentMidx = marker.id;
      }
    },
    gMapMarkerOver(marker){
      this.infoWindowPos = marker.position;
      this.infoOptions.content = marker.infoText;
      if (this.currentMidx == marker.id) {
        this.infoWinOpen = !this.infoWinOpen;
      }
      else {
        this.infoWinOpen = true;
        this.currentMidx = marker.id;
      }
    },
    filterFieldShow(way){
      let domClientWidth = document.body.clientWidth;
      this.more.field = [];
      if(way === "typeselect"){
        for(let field in this.filter){
          let fieldValue = this.filter[field];
          fieldValue.show = true;
          fieldValue.rect.right = 0;
        }
        let type = this.filter.type.value[0];
        this.filter.bedrooms.show = type === 'Residential';
        this.filter.bathrooms.show = type === 'Residential';
        this.filter.square.show = type !== 'Land';
        this.filter.year.show = type !== 'Land';
      }
      this.$nextTick(function(){
        for(let field in this.filter){
          let fieldValue = this.filter[field];
          let domField = this.$refs[field];
          if(way === "resize" && !fieldValue.rect.right){
            let domFieldRect = domField.$el.getBoundingClientRect();
            fieldValue.rect.right = domFieldRect.right;
          }
          if(way === "resize"){
            let fieldRight = fieldValue.rect.right;
            fieldValue.show = fieldRight < domClientWidth - 150;
            let fieldIndex = this.more.field.indexOf(field);
            if(fieldIndex === -1 && !fieldValue.show){
              this.more.field.push(field)
            }
            this.more.label = `More(${this.more.field.length})`;
            this.more.show = this.more.field.length;
          }
          if(way === "typeselect" && fieldValue.show){
            console.log("domField.$el:",domField)
            let domFieldRect = domField && domField.$el.getBoundingClientRect();
            fieldValue.rect.right = domFieldRect.right;
            let fieldRight = fieldValue.rect.right;
            fieldValue.show = fieldRight < domClientWidth - 150;
            let fieldIndex = this.more.field.indexOf(field);
            if(fieldIndex === -1 && !fieldValue.show){
              this.more.field.push(field)
            }
            this.more.label = `${this.$t('More')}(${this.more.field.length})`;
            this.more.show = this.more.field.length;
          }
        }
      });

    },
    async collectHouse(id,index,action){
      try {
        let res = await this.$api[action === "favourite" ? 'requestCollectionCancelSaleListings' : 'requestCollectionSaveSaleListings']({
          saleId:id
        });
        if(action === "favourite"){
          this.houseList.splice(index,1);
        }
      }catch (e){

      }
    },
    hotCityItemClick(id){
      this.filter.name.value = "";
      this.filter.name.id = "";
      this.hotCityList.forEach(function(city){
        city.value = "";
        let options = city.options
        let currentOption = options.filter(option=>option.value === id);
        if(currentOption.length > 0){
          city.value = id;
        }
      });
      if(this.$route.query.id){
        this.$router.push({name:"buy"})
      }
      this.mapbox = [];
      this.resetLoad()
    },
    resetLoad({cityclear = false,mapsearch = false} = {}){
      if(cityclear){
        this.hotCityList.forEach(city=>{
          city.value = "";
        });
      }
      this.$refs.infinitescroll.reset();
      this.$refs.infinitescroll.resume();
      this.$refs.infinitescroll.trigger();
    },
    goNameFocus(){
      this.$refs.name.focus();
    },
    async getAreaLatitudeLongitude(){
      let _this = this;
      // this.$refs.mapRef && this.$refs.mapRef.$mapPromise.then((map) => {
      //   _this.google.maps.event.addListener(map,'center_changed',function(){
      //     var bounds = map.getBounds(true);
      //     _this.mapbox = [bounds.getNorthEast().lat(),bounds.getNorthEast().lng(),bounds.getSouthWest().lat(),bounds.getSouthWest().lng()];
      //   })
      // })
    }
  },
  created() {},
  beforeRouteUpdate(to, from, next){
    this.pageStatus = false;
    next();
  },
  filters:{
    presqft(value){
      if (!value) return '';
      return value.replace(/\s*(per)\s*/,"/");
    }
  },
  async mounted() {
    this.viewStyle.height = (document.body.clientHeight - 180) + "px";
    let _this = this;
    if(this.query.action !== 'favourite'){
      window.onresize = function(){
        _this.filterFieldShow("resize");
      }
      _this.filterFieldShow("typeselect");
    }
    window.onresize = function(){
      _this.viewStyle.height = (document.body.clientHeight - 180) + "px";
    }
    this.google = await this.$gmapApiPromiseLazy();
    this.$refs.mapRef && this.$refs.mapRef.$mapPromise.then((map) => {
      this.google.maps.event.addListener(map, 'zoom_changed', function(){
        var bounds = map.getBounds(true);
        _this.mapbox = [bounds.getNorthEast().lat(),bounds.getNorthEast().lng(),bounds.getSouthWest().lat(),bounds.getSouthWest().lng()];
        _this.resetLoad({mapsearch:true});
      });
      this.google.maps.event.addListener(map, 'dragend', function(){
        var bounds = map.getBounds(true);
        _this.mapbox = [bounds.getNorthEast().lat(),bounds.getNorthEast().lng(),bounds.getSouthWest().lat(),bounds.getSouthWest().lng()];
        _this.googleCenter.lat = map.center.lat();
        _this.googleCenter.lng = map.center.lng();
        _this.resetLoad({mapsearch:true});
      });
    })
  }
}
</script>

<style scoped lang="scss">
@import "../assets/css/list.scss";
.list__body /deep/{
  .hotcity__box{
    display: flex;
    .hotcity__box--title{
      align-self: center;
    }
    .hotcity__selectbox{
      .hotcity__selectbox--select{
        width: 250px;
        .q-field__native{
          height: 30px;
        }
      }
    }
  }

  .q-item{
    min-height: 40px;
  }
  .q-field__control,.q-field__marginal,.q-field__native{
    min-height: 40px;
    height: 40px;
  }
  .phouse__search--filterlist{
    flex-wrap: nowrap;
  }
  .hotcity__box--title{
    margin-right: 20px;
    color: #666;
  }
  .phouse__item--info--updateDate{
    position: absolute;
    bottom: 10px;
    left: 10px;
    font-size: 12px;
    color: #fff;
    background: #4599d1;
    padding: 5px 3px;
    border-radius: 5px;
  }
}
.q-pa-xs{
  padding: 0;
}
.hide-search{
  position: relative;
  height: 10px;
  span{
    position: absolute;
    left: 600px;
    color: #A6A6A6;
    cursor: pointer;
  }
}
.searchNum{
  display: inline-block;
}
.label_title{
  display: inline-block;
  width: 110px;
  text-align: left;
}
.radio-btn-q{
  margin-right: 15px;
  border-radius: 5px; padding: 0 6px; border:solid 1px #A6A6A6; color:#443227;
  &.active{
    color: #1871FF; border:solid 1px #1871FF;
  }
}
.moresearchModule{
  padding: 20px 0;
  ul li{
    color: #A6A6A6;
    height:30px;
    line-height:30px;
    margin-bottom:10px;
  }
}
.openMoreSearch{
  padding: 0;
  color: #1871FF;
  border: solid 1px #1871FF;
  border-radius: 5px;
  width: 120px;
  height: 40px;
  line-height: 40px;
  text-align: center;
  cursor: pointer;
  margin-left: 10px;
  margin-top: 15px;
}
/* .q-tab--active{
  color: #1871FF;
} */
.q-separator--horizontal{
  height: 0 !important;
}
.swiper-wrapper{
  position: relative;
  .swiper-slide{
    height: calc((100% - 30px) / 2);
    img{
      height: 100%;
    }
  }
}
.tab-menu{
  height: 35px;
  line-height: 35px;
  border-bottom: solid 1px #E5E5E5;
  position: relative;
  .bottomBorder{
    position: absolute;
    width:90px;
    height:2px;
    background:#1871FF;
    left:0;
    bottom:0;
    &.right{
      left: 104px;
    }
  }
  ul{
    li{
      color: #443227;
      font: size 14px;
      cursor: pointer;
      float: left;
      padding:0 10px;
      margin-right:15px;
      &.active{
        color:#1871FF
      }
    }
  }
}
</style>
